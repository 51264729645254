<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style lang="scss" scoped>
.app {
  position: relative;
}
</style>
